.openLanguageToggle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9990;
}
.asbuilt-header {
  display: flex;
  justify-content: space-between;
  height: 123px;
  align-items: center;

  &__language {
    display: block;
    z-index: 9999;

    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }

    .asbuilt-header__language__box__langselect.open {
      display: block;
    }

    margin-left: 20px;
    &__icon {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      width: 38px;
      height: 38px;
    }

    &__box {
      position: relative;
      width: 100%;
      height: 1px;

      &__langselect {
        display: none;
      }

      &__inner {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 10px;
        margin-left: -6px;

        & ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        &__button {
          display: block;
          width: 100%;
          text-align: left;
          padding: 10px 16px;
          border: none;
          border-bottom: 1px solid lightgrey;
        }
      }
    }
  }

  &__logo {
    width: 230px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__menu {
    display: flex;
    padding: 0;
    padding-top: 10px;
    font-family: "FuturaLT-Bold";
    font-size: 16px;
    line-height: 30px;

    @media only screen and (max-width: 991px) {
      flex: 1;
      justify-content: flex-end;
    }

    &__item {
      margin-right: 48px;
      color: #3a3a3a !important;
      text-decoration: none !important;
      border-bottom: 5px solid transparent;
      padding-top: 6px;

      @media only screen and (max-width: 991px) {
        display: none;
      }

      &--active {
        border-bottom: 5px solid #86d1f3;
      }
    }

    &__contact {
      padding: 0px 16px;
      background-color: #74b9bf;
      border-radius: 10px;
      color: #ffffff !important;
      text-decoration: none !important;
      transition: all 0.3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      padding-top: 6px;

      @media only screen and (max-width: 991px) {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#74b9bf, 15%);
        transition: all 0.3s;
        z-index: -1;
      }

      &:hover {
        &:before {
          width: 100%;
        }
      }

      a {
        color: #ffffff;
        border-bottom: none !important;
      }
    }
  }

  &__hamburger-icon {
    display: none;
    width: 38px;
    margin-top: auto;
    margin-bottom: auto;

    @media only screen and (max-width: 991px) {
      display: block;
    }

    &--mobile {
      display: none;

      @media only screen and (max-width: 425px) {
        display: block;
        position: absolute;
        right: 30px;
        top: 70px;
      }
    }

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #3a3a3a;
      border-radius: 10px;
      margin-bottom: 4px;
      transition: all 0.3s cubic-bezier(1, 0.13, 0.35, 1.09);

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &--open {
      span {
        &:first-child {
          transform: rotate(45deg) translate(4.4px, 6.4px);
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:last-child {
          transform: rotate(-45deg) translate(4.4px, -6.4px);
        }
      }
    }
  }

  &__hamburger-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 400px;
    height: 100vh;
    background-color: #ffffff;
    padding: 58px 15px;
    z-index: 3;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    overflow-y: auto;
    flex-direction: column;
    font-family: "FuturaLT-Bold";
    font-size: 18px;
    line-height: 30px;

    @media only screen and (max-width: 991px) {
      display: flex;
    }

    @media only screen and (max-width: 425px) {
      width: 100%;
    }

    &__logo {
      img {
        width: 50%;
      }
    }

    &__item {
      display: block;
      color: #3a3a3a;
      text-decoration: none !important;
      margin-top: 30px;

      &--active {
        color: #86d1f3;
      }
    }

    &--open {
      transform: translateX(0);
      box-shadow: 0 4px 8px #3a3a3a;
    }
  }

  &__hamburger-overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    height: 0;
    width: 0;
    opacity: 0;
    transition: opacity 1s ease 0.1s;
    overscroll-behavior: contain;

    &--open {
      opacity: 3;
      width: 100%;
      height: 100vh;
    }
  }
}
