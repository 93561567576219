.carousselsection_root {
  width: 100vw;
  display: flex;
  justify-content: center;
  min-height: 600px;

  &__inner {
    width: 100%;
    max-width: 1140px;
    overflow: hidden;
    padding: 20px;
  }
}
