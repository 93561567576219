.caroussel_root {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 18px;
      font-family: "FuturaLT-Bold";

      @media only screen and (min-width: 768px) {
        font-size: 22px;
      }

      @media only screen and (min-width: 1200px) {
        font-size: 26px;
      }
    }

    &__arrows {
      display: flex;
      flex-direction: row;

      & img {
        width: 26px;
        height: 26px;

        @media only screen and (min-width: 768px) {
          width: 44px;
          height: 44px;
        }
        transition: all 0.4s;
        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
      }

      & img:last-child {
        margin-left: 20px;
      }
    }
  }

  &__items {
    margin-top: 20px;
    width: 100%;
    position: relative;

    &__item {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: row;
      min-width: 100%;
      box-shadow: 2px 3px 20px 2px rgba(0, 0, 0, 0.1);
      border-radius: 25px;
      padding: 16px;
      margin-right: 30px;
      transition: all 0.5s ease-out;

      @media only screen and (min-width: 768px) {
        padding: 26px;
      }

      @media only screen and (min-width: 1200px) {
        padding: 40px;
      }

      &__img {
        width: 30%;

        & img {
          border-radius: 25px;
        }
      }

      &__textbox {
        width: 70%;
        padding: 22px;
        padding-top: 10px;

        @media only screen and (min-width: 768px) {
          padding: 42px;
        }

        @media only screen and (min-width: 1200px) {
          padding: 60px;
        }

        &__title {
          font-size: 18px;
          font-family: "FuturaLT-Bold";

          @media only screen and (min-width: 768px) {
            font-size: 24px;
          }

          @media only screen and (min-width: 1200px) {
            font-size: 30px;
          }

          & span {
            color: #74b9bf;
          }
        }
      }
    }
  }
}
