.asbuilt-barhero-hero {
  display: flex;
  width: 100vw;
  &__bar {
    width: 100vw;
    height: 163px;
    background-color: #74b9bf;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 991px) {
      height: 80px;
    }

    &__icon {
      height: 101px;

      @media only screen and (max-width: 991px) {
        height: 60px;
      }
    }

    h1 {
      color: white;
      font-weight: bold;
      font-family: "FuturaLT-Bold", "Arial", sans-serif;
    }
  }
}
