.asbuilt-content-item {
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;

  &--left {
    flex-direction: row-reverse;
  }

  @media only screen and (max-width: 991px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }

  &__left {
    width: 48%;
    overflow: hidden;
    padding-top: 60px;

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  &__right {
    width: 48%;
    overflow: hidden;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    border-radius: 25px;
  }
}
