.asbuilt-herotext {
  max-width: 920px;
  text-align: center;

  &__title {
    font-family: "FuturaLT-Bold";
    font-size: 40px;
    line-height: 70px;
    color: #3a3a3a;

    @media only screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 50px;
    }

    @media only screen and (max-width: 578px) {
      font-size: 24px;
      line-height: 40px;
    }
  }

  &__desc {
    margin-top: 30px;
    font-family: "FuturaLT";
    font-size: 22px;
    line-height: 32px;
    color: #3a3a3a;

    @media only screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
