.asbuilt-footer {
  min-height: 212px;
  background-color: #3a3a3a;
  padding-top: 50px;
  padding-bottom: 50px;

  &__container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    color: white;
    @media only screen and (max-width: 991px) {
      display: block;
    }
  }

  &__menu {
    display: flex;
    list-style-type: none;
    padding: 0;

    @media only screen and (max-width: 991px) {
      justify-content: space-between;
    }

    @media only screen and (max-width: 425px) {
      flex-direction: column;
    }

    &__item {
      color: #ffffff;
      font-family: "FuturaLT-Book";
      font-size: 17px;
      line-height: 23px;
      margin-right: 128px;

      @media only screen and (max-width: 991px) {
        margin-right: 0px;
      }

      @media only screen and (max-width: 425px) {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &__title {
        line-height: 35px;
        font-family: "FuturaLT-Bold";
      }

      &__link {
        text-decoration: none;
        color: inherit;

        &:hover {
          color: #74b9bf;
        }
      }
    }
  }

  &__logo {
    display: block;
    text-align: center;

    img {
      width: 230px;
      height: 47px;
    }
  }
}
