.asbuilt-discover {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  overflow: hidden;

  @media only screen and (max-width: 991px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__img {
    width: 35%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url("../../assets/images/ASBUILT_DISCOVER_IMG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 35% center;

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  &__text {
    width: 65%;
    color: #3a3a3a;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    &__title {
      font-family: "FuturaLT-Bold";
      font-size: 44px;
      line-height: 58px;
      margin-bottom: 150px;

      @media only screen and (max-width: 991px) {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 75px;
      }
    }

    &__steps {
      &__item {
        display: flex;
        margin-bottom: 60px;

        @media only screen and (max-width: 991px) {
          margin-bottom: 30px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        &__index {
          font-family: "FuturaLT-Bold";
          font-size: 72px;
          line-height: 96px;
          margin-right: 60px;

          @media only screen and (max-width: 991px) {
            font-size: 52px;
            line-height: 76px;
          }

          @media only screen and (max-width: 425px) {
            font-size: 40px;
            line-height: 50px;
            margin-right: 30px;
          }
        }

        &__text {
          font-family: "FuturaLT-Book";
          font-size: 28px;
          line-height: 38px;

          @media only screen and (max-width: 991px) {
            font-size: 23px;
            line-height: 33px;
          }

          @media only screen and (max-width: 425px) {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
