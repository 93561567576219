@keyframes bounce {
  0% {
    top: -70px;
  }
  25%,
  75% {
    top: -55px;
  }
  50% {
    bottom: -65px;
  }
  100% {
    bottom: -60px;
  }
}

.scrollarrow {
  position: fixed;
  bottom: 16px;
  left: 0;
  height: 0px;
  width: 100vw;
  display: flex;
  justify-content: center;

  &__box {
    position: relative;
    display: none;
    @media only screen and (min-width: 1400px) {
      display: block;
    }

    &__arrow {
      position: absolute;
      top: -60px;
      left: -15px;
      animation: bounce 1s ease-out 0s infinite;
    }
  }
}
