.subbar-root {
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #86d1f3;
  padding: 36px 40px;

  &__inner {
    width: 90%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2,
    p {
      text-align: center;
    }

    h2 {
      margin-bottom: 36px;
      font-weight: bold;
      font-family: "FuturaLT-Bold";
      font-size: 24px;
    }

    p {
      font-family: "FuturaLT";
      max-width: 85%;
    }
  }
}
