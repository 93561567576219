.jobdetail-root {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 60px 0;

  &__inner {
    width: 90%;
    max-width: 1040px;
    display: flex;
    flex-direction: column;
    font-family: "FuturaLT";

    &__top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-family: "FuturaLT-Bold";

      p {
        color: #9bdbc2;
        font-size: 26px;
      }

      a {
        color: #9bdbc2;
      }
    }

    &__main {
      display: flex;
      flex-direction: column;

      h2,
      h3 {
        font-family: "FuturaLT-Bold";
      }

      h3 {
        color: #9bdbc2;
        font-size: 22px;
        margin-top: 35px;
      }

      a,
      strong {
        color: #9bdbc2;
        font-weight: bold;
      }
    }
  }
}
