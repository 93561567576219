.technical_root {
  min-height: calc(100vh - 123px);
  background-color: #86d1f3;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    padding: 60px 40px;

    &__icons {
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      max-width: 1100px;

      &__icon {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__img {
          height: 88px;

          @media only screen and (max-width: 991px) {
            height: 60px;
          }

          @media only screen and (max-width: 425px) {
            height: 30px;
          }
        }

        &__text {
          margin-top: 30px;
          font-family: "FuturaLT-Bold";
          font-size: 24px;
          line-height: 33px;
          border-bottom: 5px solid #86d1f3;
          color: #3a3a3a;
          text-align: center;

          @media only screen and (max-width: 991px) {
            margin-top: 20px;
            font-size: 16px;
            line-height: 23px;
          }

          @media only screen and (max-width: 425px) {
            margin-top: 10px;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    &__title {
      font-family: "FuturaLT-Bold";
      font-size: 40px;
      line-height: 70px;
      color: #3a3a3a;
      margin-bottom: 0;

      @media only screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 50px;
      }

      @media only screen and (max-width: 578px) {
        font-size: 24px;
        line-height: 40px;
      }
    }

    &__text {
      margin-top: 0px;
      font-family: "FuturaLT";
      font-size: 22px;
      line-height: 32px;
      color: #3a3a3a;

      @media only screen and (max-width: 991px) {
        font-size: 18px;
        line-height: 20px;
      }
    }

    &__button {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 70px;
      border-radius: 10px;
      margin-right: 25px;
      font-family: "FuturaLT-Bold";
      font-size: 22px;
      line-height: 23px;
      color: #ffffff !important;
      text-decoration: none !important;
      transition: all 0.3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      text-align: center;
      background-color: #74b9bf;
      padding: 16px 30px;

      @media only screen and (max-width: 991px) {
        font-size: 15px;
        line-height: 16px;
      }

      @media only screen and (max-width: 425px) {
        margin-right: 0px;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#74b9bf, 15%);
        transition: all 0.3s;
        z-index: -1;
      }

      &:hover {
        &:before {
          width: 100%;
        }
      }

      &:last-child {
        margin-right: 0px;
        @media only screen and (max-width: 425px) {
          margin-top: 15px;
        }
      }

      &--left {
        background-color: #74b9bf;
      }

      &--right {
        background-color: #86d1f3;
      }
    }
  }
}
