.jobssection-root {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 60px 0;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 90%;
    max-width: 1040px;

    &__job {
      width: 100%;
      border-radius: 15px;
      padding: 40px;
      margin-bottom: 60px;
      -webkit-box-shadow: -1px 1px 8px -1px rgba(0, 0, 0, 0.26);
      box-shadow: -1px 1px 8px -1px rgba(0, 0, 0, 0.26);

      @media (min-width: 768px) {
        width: 46%;
      }

      p.vac {
        font-weight: bold;
        color: #9bdbc2;
        font-family: "FuturaLT-Bold";
        font-size: 24px;
      }

      h3 {
        font-weight: bold;
        font-family: "FuturaLT-Bold";
      }

      p {
        font-family: "FuturaLT";
      }

      a {
        color: #7eb39e;
      }
    }
  }
}
