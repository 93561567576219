.asbuilt-contact-section {
  min-height: 100vh;
  background-image: url("../../assets/images/ASBUILT_CONTACT_SECTION_IMG.svg");
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: center bottom;
  display: flex;
  color: #ffffff;

  &--page {
    background-color: rgb(134, 209, 243);
    min-height: calc(100vh - 123px);
  }

  &__container {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      display: block;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__left {
    width: 48%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    &__toptitle {
      display: flex;
      font-family: "FuturaLT";
      font-size: 30px;
      margin-top: 120px;

      @media only screen and (max-width: 1200px) {
        font-size: 26px;
      }

      @media only screen and (max-width: 991px) {
        font-size: 22px;
      }

      @media only screen and (max-width: 425px) {
        font-size: 18px;
      }

      &--section {
        color: #3a3a3a;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      font-family: "FuturaLT-Bold";
      font-size: 40px;
      line-height: 48px;

      @media only screen and (max-width: 1200px) {
        font-size: 32px;
        line-height: 38px;
      }

      @media only screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 42px;
      }

      @media only screen and (max-width: 425px) {
        font-size: 24px;
        line-height: 36px;
      }

      &--section {
        color: #3a3a3a;
      }
    }

    &__desc {
      margin-top: 80px;
      height: auto;
      display: flex;
      align-items: center;
      font-family: "FuturaLT";
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      max-width: 460px;

      @media only screen and (max-width: 767px) {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @media only screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }

      &--section {
        color: #3a3a3a;
      }
    }
  }

  &__right {
    padding: 30px 0;
    width: 48%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
