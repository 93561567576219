.asbuilt-textbox {
  &:nth-child(2) {
    margin-top: 50px;
  }

  &__title {
    font-family: "FuturaLT-Bold";
    font-size: 30px;
    line-height: 40px;
    color: #3a3a3a;
    margin-bottom: 35px;

    @media only screen and (max-width: 991px) {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 25px;
    }
  }

  &__desc {
    font-family: "FuturaLT";
    font-size: 22px;
    line-height: 32px;
    color: #3a3a3a;

    @media only screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
