* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'FuturaLT';
  font-style: normal;
  src: url('./assets/fonts/FuturaLT.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/FuturaLT.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/FuturaLT.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/FuturaLT.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./assets/fonts/FuturaLT.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/FuturaLT.svg#FuturaLT')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'FuturaLT-Bold';
  font-style: normal;
  src: url('./assets/fonts/FuturaLT-Bold.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/FuturaLT-Bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/FuturaLT-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/FuturaLT-Bold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./assets/fonts/FuturaLT-Bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/FuturaLT-Bold.svg#FuturaLT-Bold') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'FuturaLT-Book';
  font-style: normal;
  src: url('./assets/fonts/FuturaLT-Book.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/FuturaLT-Book.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/FuturaLT-Book.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/FuturaLT-Book.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./assets/fonts/FuturaLT-Book.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/FuturaLT-Book.svg#FuturaLT-Book') format('svg'); /* Legacy iOS */
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
