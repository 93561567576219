.asbuilt-contact-thanks {
  width: 100%;
  text-align: center;
  min-height: 600px;
  padding: 60px 55px 45px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 1, 1, 0.1);
  -moz-box-shadow: 0px 0px 35px 0px rgba(0, 1, 1, 0.1);
  box-shadow: 0px 0px 35px 0px rgba(0, 1, 1, 0.1);
  font-family: "FuturaLT";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h2 {
    color: black;
    font-size: 36px;
    text-transform: uppercase;
    font-family: "FuturaLT-Bold";
  }

  & p {
    color: black;
    font-size: 20px;
  }
}

.asbuilt-contact-form {
  padding: 60px 55px 45px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 1, 1, 0.1);
  -moz-box-shadow: 0px 0px 35px 0px rgba(0, 1, 1, 0.1);
  box-shadow: 0px 0px 35px 0px rgba(0, 1, 1, 0.1);

  label {
    color: rgb(88, 88, 88);
    text-transform: uppercase;
    font-size: 20px;
  }

  @media only screen and (max-width: 991px) {
    padding: 30px 25px 20px;
  }

  &__item {
    width: 100%;
    height: 57px;
    padding: 20px;
    margin-bottom: 22px;
    font-family: "FuturaLT";
    font-size: 19px;
    line-height: 20px;

    @media only screen and (max-width: 991px) {
      height: 37px;
      font-size: 15px;
      line-height: 16px;
      padding: 10px;
    }

    &--message {
      height: 140px;
    }
  }

  &__captcha {
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
  }

  &__submit {
    width: 100%;
    height: 60px;
    border: none;
    outline: none !important;
    border-radius: 10px;
    background-color: #3a3a3a;
    color: #ffffff;
    font-family: "FuturaLT-Bold";
    font-size: 22px;
    line-height: 23px;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @media only screen and (max-width: 991px) {
      font-size: 15px;
      line-height: 16px;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken(#74b9bf, 15%);
      transition: all 0.3s;
      z-index: -1;
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
}
