.asbuilt-homepage-hero {
  position: relative;
  overflow: hidden;
  margin-bottom: 150px;

  @media only screen and (max-width: 991px) {
    margin-bottom: 75px;
  }

  &__left-img {
    width: 50%;
    position: absolute;
    top: -233px;
    left: -668px;
    z-index: -1;

    @media only screen and (max-width: 1280px) {
      display: none;
    }
  }

  &__right-img {
    width: 35%;
    position: absolute;
    bottom: 0px;
    right: -10px;
    z-index: -1;

    @media only screen and (max-width: 1280px) {
      display: none;
    }
  }

  &__top {
    min-height: calc(100vh - 123px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__focus {
      margin-top: 40px;
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (min-width: 425px) {
        flex-direction: row;
        margin-top: 80px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
        margin-bottom: 40px;

        & img {
          width: 60px;
          height: 60px;
          @media only screen and (min-width: 992px) {
            width: 98px;
            height: 98px;
          }
        }

        & span {
          margin-top: 20px;
          font-family: "FuturaLT-Bold";
          text-align: center;
        }
      }
    }

    &__buttons {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: center;

      @media only screen and (max-width: 425px) {
        display: block;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 385px;
        height: 70px;
        border-radius: 10px;
        margin-right: 25px;
        font-family: "FuturaLT-Bold";
        font-size: 22px;
        line-height: 23px;
        color: #ffffff !important;
        text-decoration: none !important;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-align: center;

        @media only screen and (max-width: 991px) {
          width: 50%;
          font-size: 15px;
          line-height: 16px;
        }

        @media only screen and (max-width: 425px) {
          width: 100%;
          margin-right: 0px;
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: darken(#74b9bf, 15%);
          transition: all 0.3s;
          z-index: -1;
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }

        &:last-child {
          margin-right: 0px;
          @media only screen and (max-width: 425px) {
            margin-top: 15px;
          }
        }

        &--left {
          background-color: #74b9bf;
        }

        &--right {
          background-color: #86d1f3;
        }
      }
    }
  }

  &__bottom {
    min-height: calc(100vh - 123px);
    margin-top: 200px;
    margin-bottom: 100px;

    @media only screen and (max-width: 991px) {
      margin-top: 50px;
    }

    @media only screen and (max-width: 425px) {
      margin-top: 25px;
    }

    &__focus {
      margin-top: 40px;
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: 425px) {
        flex-direction: row;
        margin-top: 80px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;

        & img {
          width: 60px;
          height: 60px;
          @media only screen and (min-width: 992px) {
            width: 98px;
            height: 98px;
          }
        }

        & span {
          margin-top: 20px;
          font-family: "FuturaLT-Bold";
          text-align: center;
        }
      }
    }

    &__text {
      width: 50%;

      @media only screen and (max-width: 991px) {
        width: 70%;
      }

      @media only screen and (max-width: 425px) {
        width: 100%;
      }
    }

    &__icon {
      display: flex;
      margin-top: 100px;
      justify-content: space-between;

      @media only screen and (max-width: 425px) {
        margin-top: 50px;
      }

      &__item {
        text-align: center;
        text-decoration: none !important;
        color: #3a3a3a !important;

        &__icon {
          height: 101px;

          @media only screen and (max-width: 991px) {
            height: 60px;
          }

          @media only screen and (max-width: 425px) {
            height: 30px;
          }
        }

        &__text {
          margin-top: 30px;
          font-family: "FuturaLT-Bold";
          font-size: 30px;
          line-height: 33px;
          border-bottom: 5px solid #86d1f3;

          @media only screen and (max-width: 991px) {
            margin-top: 20px;
            font-size: 20px;
            line-height: 23px;
          }

          @media only screen and (max-width: 425px) {
            margin-top: 10px;
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
